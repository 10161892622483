import React from 'react';
import styled from 'styled-components';

import Typography from '../Typography/Typography';

const PostHeading = ({ post }) => {
	const { title, description, date, jobs } = post;
	return (
		<PostHeadingUI>
			<Typography>
				<ProjectTitleUI>{title}</ProjectTitleUI>
			</Typography>
			<DescriptionGridUI>
				<Typography>
					<SubHeadingUI>(A)</SubHeadingUI>
					<PrimaryTextUI>{description}</PrimaryTextUI>
				</Typography>
				<Typography>
					<SubHeadingUI>(B)</SubHeadingUI>
					{jobs && jobs.map(job => <SecondaryTextUI key={job}>{job}</SecondaryTextUI>)}
				</Typography>
				<Typography>
					<SubHeadingUI>(C)</SubHeadingUI>
					{date.split('\n').map(d => (
						<SecondaryTextUI>{d}</SecondaryTextUI>
					))}
				</Typography>
			</DescriptionGridUI>
		</PostHeadingUI>
	);
};

const PostHeadingUI = styled.div`
	margin: 0 auto;
	padding: ${props => props.theme.paddingDesktop};
	padding-top: calc(
		${props => props.theme.headerHeightDesktop} + ${props => props.theme.paddingDesktop}
	);
	@media (max-width: ${props => props.theme.breakpointMobile}) {
		padding: ${props => props.theme.paddingMobile};
		padding-top: calc(
			${props => props.theme.headerHeightMobile} + ${props => props.theme.paddingMobile}
		);
	}
`;

const DescriptionGridUI = styled.div`
	display: grid;
	grid-template-columns: 50% 30% 20%;
	@media (max-width: ${props => props.theme.breakpointMobile}) {
		display: block;
	}
	div {
		padding-right: 24px;
	}
`;

const ProjectTitleUI = styled.h2`
	border: 1px solid transparent;
	display: inline-block;
	font-family: 'Untitled Sans', serif;
	margin: 0 !important;
	a {
		color: #aaa;
		text-decoration: none;
	}
	&.active {
		background: ${props => props.theme.linkActiveColor};
		a {
			color: #222;
		}
	}
`;

const SubHeadingUI = styled.h5`
	margin-bottom: 0.25rem !important;
`;

const SecondaryTextUI = styled.div`
	font-size: 0.9rem;
`;

const PrimaryTextUI = styled(Typography)`
	font-size: 1.1rem;
	letter-spacing: 0;
	font-family: 'Wremena', serif;
	color: #222;
`;

export default PostHeading;

import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';

import Layout from '../layout';
import PostHeading from '../components/PostHeading/PostHeading';
import SEO from '../components/SEO/SEO';
import config from '../../data/SiteConfig';
import Header from '../components/Header/Header';
import theme from '../components/theme';
import Fade from '../components/Fade/Fade';

export default class PostTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
		};
	}

	componentDidMount() {
		this.setState({
			isLoaded: true,
		});
	}

	render() {
		const { data, pageContext } = this.props;
		const { isLoaded } = this.state;
		const { slug } = pageContext;
		const postNode = data.markdownRemark;
		const post = postNode.frontmatter;
		if (!post.id) {
			post.id = slug;
		}
		if (!post.category_id) {
			post.category_id = config.postDefaultCategoryID;
		}
		return (
			<ThemeProvider theme={theme}>
				<Layout>
					<div>
						<Helmet>
							<title>{`${post.title} | ${config.siteTitle}`}</title>
						</Helmet>
						<SEO postPath={slug} postNode={postNode} postSEO />
						<Header />
						<Fade isLoaded={isLoaded}>
							<PostHeading post={post} />
							<PostContainerUI>
								<div dangerouslySetInnerHTML={{ __html: postNode.html }} />
							</PostContainerUI>
						</Fade>
					</div>
				</Layout>
			</ThemeProvider>
		);
	}
}

const PostContainerUI = styled.div`
	font-family: 'Untitled Sans', sans-serif;
	margin: auto;
	margin-bottom: 10vh;
	padding-bottom: ${props => props.theme.paddingDesktop};
	max-width: 792px;
	@media (max-width: ${props => props.theme.breakpointMobile}) {
		padding: ${props => props.theme.paddingMobile};
	}
	img {
		width: 100%;
	}
	.gatsby-resp-iframe-wrapper {
		padding-bottom: 56% !important;
	}
	p, h1, h2, h3 {
		color: rgba(0, 0, 0, 0.84);
		line-height: 1.5;
		font-weight: normal;
		@media (min-width: ${props => props.theme.breakpointMobile}) {
			margin-left: 4rem;
		}
	}
	p {
		font-family: 'Wremena', serif;
		font-size: 1rem;
		color: #222;
		max-width: 600px;
	}
	a {
		color: black;
		cursor: crosshair;
		&:hover {
			background: ${props => props.theme.linkActiveColor};
		}
	}
	.gatsby-resp-image-wrapper {
		max-width: 1192px !important;
	}
`;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			timeToRead
			excerpt
			frontmatter {
				title
				description
				cover {
					childImageSharp {
						fluid(maxWidth: 1920) {
							...GatsbyImageSharpFluid
						}
					}
				}
				date
				category
				tags
				jobs
			}
			fields {
				slug
				date
			}
		}
	}
`;
